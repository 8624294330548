<template>
    <div class="feature" style="background: url('https://trinks.com.my/wp-content/uploads/2020/07/Curves-1.png')">
        <section>
            <div class="container main-container">
            <!--Section One -->
            <section class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 header-col">
                            <h1 class="header-title">Our Feature Wines</h1>
                            <p class="header-sub">A History of more than 156 years of wine making</p>
                        </div>
                    </div>
                    <div class="spacing"></div>
                </div>
            </section>

            </div>
        </section>
        <section style="background: url('https://trinks.com.my/wp-content/uploads/2020/07/5564.png'); background-size: cover; background-position: center">
            <div class="container-fluid">
                <div class="spacing"></div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-6 feature-wine-col">
                        <div class="row">
                            <div class="col-12">
                                <h1>Signature Wines</h1>
                            </div>
                            <div class="col-12">
                                <carousel :per-page="6" :per-page-custom="[[100, 3], [768, 6], [1025, 6] ]" :pagination-enabled="true" :center-mode="false" :scroll-per-page="true" :navigation-enabled="true" :pagination-active-color="'#BD9E57'">
                                    <slide>
                                        <a href="https://trinks.com.my/product/faustino-i-gran-reserva-2009"><img class="img-fluid" src="../assets/images/signatures/faustino.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/campillo-finca-cuesta-clara-raro"><img class="img-fluid" src="../assets/images/signatures/campillo-fincaCuestaClara-Raro.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/faustino-icon-edition"><img class="img-fluid" src="../assets/images/signatures/faustino-iconEdition.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/marques-vitoria-gran-reserva"><img class="img-fluid" src="../assets/images/signatures/vitoria.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/portia-prima"><img class="img-fluid" src="../assets/images/signatures/portia.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/portia-triennia"><img class="img-fluid" src="../assets/images/signatures/portia-trienia.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/campillo-gran-reserva-1994"><img class="img-fluid" src="../assets/images/signatures/campillo-granReserva.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/fortius-gran-reserva"><img class="img-fluid" src="../assets/images/signatures/fortius-granReserva.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/cava-faustino-brut-reserva"><img class="img-fluid" src="../assets/images/signatures/FaustinoCavaBrut-new.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/faustino-i-reserva-75th-anniversary"><img class="img-fluid" src="../assets/images/signatures/Faustino_75th_Anniversary.png"></a>
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 feature-wine-col">
                        <div class="row">
                            <div class="col-12">
                                <h1>Best Seller</h1>
                            </div>
                            <div class="col-12">
                                <carousel :per-page="6" :per-page-custom="[[100, 3], [768, 6], [1025, 6] ]" :pagination-enabled="true" :center-mode="false" :scroll-per-page="true" :navigation-enabled="true" :pagination-active-color="'#BD9E57'">
                                    <slide>
                                        <a href="https://trinks.com.my/product/faustino-i-gran-reserva-2009"><img class="img-fluid" src="../assets/images/best_sellers/faustino.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/campillo-crianza"><img class="img-fluid" src="../assets/images/best_sellers/campillo-crianzaNuevo.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/faustino-art-collection-reserva"><img class="img-fluid" src="../assets/images/best_sellers/Faustino-Art-Collection_Willy-Ramos.-Reserva2013.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/campillo-blanco-fermentado"><img class="img-fluid" src="../assets/images/best_sellers/campillo-white.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/marques-vitoria-crianza"><img class="img-fluid" src="../assets/images/best_sellers/mVitoria-crianza.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/campillo-el-nino"><img class="img-fluid" src="../assets/images/best_sellers/Campillo_El-Nino_Mockup.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/fortius-rosado"><img class="img-fluid" src="../assets/images/best_sellers/fortius-rosado.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/portia-crianza"><img class="img-fluid" src="../assets/images/best_sellers/portia_crianza.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/faustino-ix-tempranillo"><img class="img-fluid" src="../assets/images/best_sellers/faustino-IX-center.png"></a>
                                    </slide>
                                    <slide>
                                        <a href="https://trinks.com.my/product/portia-roble"><img class="img-fluid" src="../assets/images/best_sellers/portia-roble.png"></a>
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacing"></div>
                <!--<div class="row">
                    <div class="col-12">
                        <h1>Promotion Packages</h1>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 promotions-col">
                            <img class="img-fluid" src="../assets/images/promotions/1.jpg">
                        </div>
                        <div class="col-12 promotions-col">
                            <img class="img-fluid" src="../assets/images/promotions/2.jpg">
                        </div>
                        <div class="col-12 promotions-col">
                            <img class="img-fluid" src="../assets/images/promotions/3.jpg">
                        </div>
                    </div>
                </div>-->
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Feature"
    }
</script>

<style scoped>

</style>
